import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMWLicenceService } from '../../../../../shared/emw_licence.service';
import { EMWDeviceService } from '../../../../../shared/emw_device.service';
import { EMWDevice } from '../../../../../shared/models/emw_device';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/shared/auth.service';
declare var $: any;

@Component({
    selector: 'app-emw-licence-device-list',
    templateUrl: './licence-device-list.component.html',
    styleUrls: ['./licence-device-list.component.css']
})
export class EMWLicenceDeviceListComponent implements OnInit {

    _licenceKey: string;
    devices: EMWDevice[];
    loading = false;
    epicorUser = true;
    constructor(private licenceSvc: EMWLicenceService,
      private deviceSvc: EMWDeviceService,
      public ngxModalSvc: NgxSmartModalService,
      private router: Router,
      private authSvc: AuthService) { }

    ngOnInit() {
      this.epicorUser = this.authSvc.isEpicorUser();
        setTimeout(() => {
            this.ngxModalSvc.getModal('licenceDeviceListModal').onDataAdded.subscribe((data: string) => {
                console.log('Data', data);
                this._licenceKey = data;
                this.loadDevices();
            });
        }, 0);

    }

    loadDevices() {
        // load device list for key
        this.loading = true;
        if (this._licenceKey != null && this._licenceKey !== '') {
            this.deviceSvc.getDevicesForLicence(this._licenceKey).then((data) => {
                this.devices = data;
                this.loading = false;
                // $(document).ready(function () {
                //     console.log('showing table');
                //     (<any>$('#deviceTable')).DataTable();
                // });
            }, () => {
                this.loading = false;
            });
        } else {
            console.log('no key');
            this.devices = new Array<EMWDevice>();
            this.loading = false;
        }
    }

    openDeviceModal(device: EMWDevice) {
      this.router.navigate(['emw/device', device.DeviceId]);
    }

    openAddDeviceModal() {
      this.router.navigate(['emw/device', 'add', this._licenceKey]);
    }
}
