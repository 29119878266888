import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(private auth: AuthService, private messageSvc: MessageService) { }

    public handleError(err: any) {
        console.log('Http Error:', err);
        if (err.status === 401) {
            this.auth.logout();
        } else {
            this.messageSvc.open('Error', err.error.errTitle, err.error.errMessage);
        }
    }
}
