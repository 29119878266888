import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Log } from '../../shared/models/log';
import { LogService } from '../../shared/log.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit, OnDestroy {
  @ViewChild('filterBox') filterInput: ElementRef;
  logs: Log[];
  loading = true;
  updatedTime = 'N/A';
  totalSize = 0;
  offset = 0;
  limit = 50;
  filter = '';
  
  debounce = new BehaviorSubject<string>('');
  
  constructor(private logSvc: LogService) { }

  ngOnInit() {
    this.limit = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : 50;
    this.debounce.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.offset = 0;
        console.log('Debounced');
        this.loadLogs();
      });
  }

  ngOnDestroy() {
    this.debounce.unsubscribe();
  }

  filterChange() {
    this.debounce.next(this.filter);
  }

  paginate(options) {
    console.log(options);
    this.offset = options.offset;
    this.limit = options.limit;
    this.loadLogs();
  }

  loadLogs(): void {
    console.log('Page Size:', this.limit, 'Offset:', this.offset);
    this.loading = true;
    this.logSvc.getLogs(this.offset, this.limit, this.filter).then((data) => {
      console.log(data);
      this.logs = data.logs;
      this.totalSize = data.total;
      this.updatedTime = this.logSvc.getUpdatedTimeString();
      this.loading = false;
      setTimeout(() => {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 50);
    }, () => {
      this.loading = false;
    });
  }

}
