import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public router: Router, public http: HttpClient, private messageSvc: MessageService) {
  }

  public isEpicorUser(): boolean {
    const epicorUser = localStorage.getItem('epicorUser');
    return epicorUser == null ? true : epicorUser.toString() === 'true' ? true : false;
  }

  public isAuthenticated(): boolean {
    const authToken = localStorage.getItem('authToken');
    return (authToken != null && authToken !== '');
  }

  public login(id: string, password: string): Promise<boolean> {
    const licensingServerUrl = environment.apiUrl;
    return new Promise((resolve, reject) => {

      const userAndPassword = id + ':' + password;

      this.http.get(licensingServerUrl,
        { headers: { 'Credentials': btoa(userAndPassword) }, observe: 'response' })
        .subscribe((data: any) => {
          if (data.body && data.body.token != null) {
            localStorage.setItem('authToken', data.body.token);
            localStorage.setItem('epicorUser', data.body.epicorUser);
            localStorage.setItem('product', 'EMW');
            resolve(true);
          } else {
            reject(false);
          }
        }, err => {
          console.log('error:', err);
          this.messageSvc.open('Error', err.error.errorTitle, err.error.errMessage);
          reject(false);
        });
    });
  }

  public logout(): void {
    localStorage.removeItem('authToken');
    console.log('logged out');
    this.router.navigate(['login']);
  }

  public getProduct(): string {
    return localStorage.product;
  }

  public changeProduct(product: string): void {
    if (this.isEpicorUser()) {
      localStorage.setItem('product', 'EMW');
    } else {
      if (localStorage.getItem('product') !== product) {
        localStorage.setItem('product', product);
        this.router.navigate(['/dashboard']);
      }
      localStorage.setItem('product', product);
    }
  }
}
