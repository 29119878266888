export class EMWDevice {
    DeviceId: string;
    LicenceKey: string;
    TokenId: string;
    DeregisteredNotes: string;
    Deregistered: boolean;
    Manufacturer: string;
    Model: string;
    Platform: string;
    PlatformVersion: string;
    AppVersion: string;
    LastCheckDate: string;
    LastModified: string;
    DateRegistered: string;
    DeregisteredDate: string;
}
