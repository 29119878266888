import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { EMWLicenceService } from '../../../../shared/emw_licence.service';
import { EMWLicence } from '../../../../shared/models/emw_licence';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-emw-licences',
  templateUrl: './emw-licences.component.html',
  styleUrls: ['./emw-licences.component.css']
})
export class EMWLicencesComponent implements OnInit, OnDestroy {
  @ViewChild('filterBox') filterInput: ElementRef;
  licences: EMWLicence[];
  selectedLicence: EMWLicence;
  loading = true;
  updatedTime = 'N/A';
  totalSize = 0;
  offset = 0;
  limit = 50;
  filter = '';
  epicorUser = true;
  debounce = new BehaviorSubject<string>('');

  constructor(private licenceSvc: EMWLicenceService,
    private ngxModalSvc: NgxSmartModalService,
    private router: Router,
    private authSvc: AuthService) { }

  ngOnInit() {
    this.epicorUser = this.authSvc.isEpicorUser();
    this.limit = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : 50;
    this.debounce.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.refreshList();
      });
  }

  ngOnDestroy() {
    this.debounce.unsubscribe();
  }

  filterChange() {
    this.debounce.next(this.filter);
  }

  paginate(options) {
    console.log(options);
    this.offset = options.offset;
    this.limit = options.limit;
    this.loadLicences();
  }

  refreshList() {
    this.offset = 0;
    this.loadLicences();
  }

  loadLicences(): void {
    this.loading = true;
    this.licenceSvc.getLicences(this.offset, this.limit, this.filter).then((data) => {
      this.licences = data.licences;
      this.totalSize = data.total;
      console.log(data.total);
      this.updatedTime = this.licenceSvc.getUpdatedTimeString();
      this.loading = false;
      setTimeout(function () {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 50);
    }, () => {
      this.loading = false;
    });
  }

  openLicenceModal(licence: EMWLicence): void {
    this.router.navigate(['/emw/licence', licence.LicenceKey]);
  }

  addLicence(): void {
    this.router.navigate(['/emw/licence', 'add']);
  }
}
