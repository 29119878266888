import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { RMSServer } from './models/rms_server';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ErrorService } from './error.service';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class RMSServerService {
    serverUrl = environment.apiUrl + 'rms/server';
    constructor(private http: HttpClient, private errorSvc: ErrorService) { }

    getServersForLicence(licenceKey: String): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?licencekey=' + licenceKey,
              { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
                  console.log(data.body);
                  const servers = new Array<RMSServer>();
                  data.body.data.forEach(element => {
                      let server = new RMSServer();
                      server = element;
                      servers.push(server);
                  });
                  resolve(servers);
              }, err => {
                this.errorSvc.handleError(err);
                  reject(err);
              });
        });
    }

    getServers(offset, limit, filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?offset=' + offset + '&limit=' + limit + '&filter=' + filter,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
                    console.log('data.body', data.body);
                    const servers = new Array<RMSServer>();
                    data.body.data.servers.forEach(element => {
                        let server = new RMSServer();
                        server = element;
                        servers.push(server);
                    });
                    resolve({ total: data.body.data.total, servers: servers });
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    updateServer(server: RMSServer): Promise<any> {
        const serverPayload = {
            data: {
                serverName: server.ServerName,
                licenceKey: server.LicenceKey,
                numOfInstalls: server.NumOfInstalls
            }
        };
        return new Promise((resolve, reject) => {
            this.http.put(this.serverUrl, serverPayload,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
                    resolve(response.body);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    deleteServer(server: RMSServer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.serverUrl + '?servername=' + server.ServerName,
            { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
                    resolve(response.body);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    getUpdatedTimeString(): string {
        const currentTime = new Date();
        return currentTime.toLocaleTimeString('en-AU');
    }
}
