import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../shared/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading = false;
  emwLicences = 0;
  emwDevices = 0;
  rmsLicences = 0;
  rmsServers = 0;
  logs = 0;
  updatedTime = 'N/A';
  constructor(private dashboardSvc: DashboardService) { }

  ngOnInit() {
    this.loadLogs();
  }

  loadLogs(): void {
    this.loading = true;
    this.dashboardSvc.getStatistics().then((response) => {
      console.log(response);
      this.emwLicences = response.data.emwLicenceCount;
      this.emwDevices = response.data.emwDeviceCount;
      this.rmsLicences = response.data.rmsLicenceCount;
      this.rmsServers = response.data.rmsServerCount;
      this.logs = response.data.logCount;
      this.updatedTime = this.dashboardSvc.getUpdatedTimeString();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
