import { Component } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { MessageService } from './shared/message.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  isLoggedin = false;
  epicorUser = true;
  product = 'EMW';
  production = environment.production;

  constructor(private auth: AuthService, private router: Router, public messageSvc: MessageService) {
    router.events.subscribe((val) => {
      this.isLoggedin = auth.isAuthenticated();
      this.product = auth.getProduct();
      this.epicorUser = auth.isEpicorUser();
    });
  }

  onLogout(): void {
    (<any>$('#logoutModal')).modal('hide');
    console.log('on log out');
    this.auth.logout();
    console.log('logout');
    this.router.navigate(['login']);
  }

  productChange(product: string) {
    this.product = product;
    this.auth.changeProduct(this.product);
  }

  isEMW() {
    return this.product === 'EMW';
  }

  isRMS() {
    return this.product === 'RMS';
  }
}
