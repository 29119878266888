import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Log } from './models/log';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class LogService {
    serverUrl = environment.apiUrl + 'logs';

    constructor(private http: HttpClient, private errorSvc: ErrorService) { }

    getLogs(offset, limit, filter): Promise<any> {
        // const authToken = btoa(localStorage.getItem('userId') + ':' + localStorage.getItem('token'));
        // console.log('auth token plus login:', authToken);
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?offset=' + offset + '&limit=' + limit + '&filter=' + filter,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' })
                .subscribe((data: any) => {
                    const logs = new Array<Log>();
                    if (data.body.valid) {
                        console.log(data.body);
                        data.body.data.logs.forEach(element => {
                            const log = new Log();
                            log.logTime = element.LogTime;
                            log.logType = element.LogType;
                            log.message = element.Message;
                            logs.push(log);

                        });
                        resolve({ total: data.body.data.total, logs: logs });
                    } else {
                        reject(data.body);
                    }
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    getUpdatedTimeString(): string {
        const currentTime = new Date();
        return currentTime.toLocaleTimeString('en-AU');
    }
}
