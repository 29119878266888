import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { EMWLicencesComponent } from './components/emw/licences/list/emw-licences.component';
import { EMWLicenceDetailsComponent } from './components/emw/licences/detail/licence-details.component';
import { EMWLicenceDeviceListComponent } from './components/emw/licences/detail/licence-device-list/licence-device-list.component';
import { RMSLicencesComponent } from './components/rms/licences/rms-licences.component';
import { RMSLicenceDetailsComponent } from './components/rms/licences/modals/licence-details/licence-details.component';
import { RMSLicenceServerListComponent }from './components/rms/licences/modals/licence-details/licence-server-list/licence-server-list.component';
import { EMWAddDeviceComponent } from './components/emw/devices/modals/add-device/add-device.component';
import { EMWDeviceDetailsComponent } from './components/emw/devices/detail/device-details.component';
import { EMWDevicesComponent } from './components/emw/devices/list/emw-devices.component';
import { RMSEditServerComponent } from './components/rms/servers/modals/edit-server/edit-server.component';
import { RMSServersComponent } from './components/rms/servers/rms-servers.component';
import { LogsComponent } from './components/logs/logs.component';
import { LicenceCheckerComponent } from './components/licence-checker/licence-checker.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { LoginComponent } from './components/login/login.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MessageModalComponent,
    EMWLicencesComponent,
    EMWLicenceDetailsComponent,
    EMWLicenceDeviceListComponent,
    EMWAddDeviceComponent,
    EMWDeviceDetailsComponent,
    EMWDevicesComponent,
    RMSLicencesComponent,
    RMSLicenceDetailsComponent,
    RMSLicenceServerListComponent,
    RMSEditServerComponent,
    RMSServersComponent,
    LogsComponent,
    LicenceCheckerComponent,
    PaginatorComponent,
    LoginComponent,
    LoadingIndicatorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
