import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { RMSServer } from '../../../shared/models/rms_server';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RMSServerService } from '../../../shared/rms_server.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-rms-servers',
  templateUrl: './rms-servers.component.html',
})
export class RMSServersComponent implements OnInit, OnDestroy {
  @ViewChild('filterBox') filterInput: ElementRef;
  servers: RMSServer[];
  loading = true;
  updatedTime = 'N/A';
  totalSize = 0;
  offset = 0;
  limit = 50;
  filter = '';
  debounce = new BehaviorSubject<string>('');

  constructor(private serverSvc: RMSServerService, public ngxModalSvc: NgxSmartModalService) {}

  ngOnInit() {
    this.limit = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : 50;
    this.debounce.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.offset = 0;
        this.loadServers();
      });
  }

  ngOnDestroy() {
    this.debounce.unsubscribe();
  }

  filterChange() {
    this.debounce.next(this.filter);
  }

  paginate(options) {
    this.offset = options.offset;
    this.limit = options.limit;
    this.loadServers();
  }

  loadServers(): void {
    this.loading = true;
    this.serverSvc.getServers(this.offset, this.limit, this.filter).then((data) => {
      this.servers = data.servers;
      this.totalSize = data.total;
      this.updatedTime = this.serverSvc.getUpdatedTimeString();
      this.loading = false;
      setTimeout(function () {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 50);
    }, () => {
      this.loading = false;
    });
  }

  openServerModal(server: RMSServer) {
    this.ngxModalSvc.setModalData(server, 'editServerModal', true);
    this.ngxModalSvc.getModal('editServerModal').open();
  }
}
