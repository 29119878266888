import { Component, OnInit } from '@angular/core';
import { RMSLicenceService } from '../../../../../../shared/rms_licence.service';
import { RMSServerService } from '../../../../../../shared/rms_server.service';
import { RMSServer } from '../../../../../../shared/models/rms_server';
import { NgxSmartModalService } from 'ngx-smart-modal';
declare var $: any;

@Component({
    selector: 'app-rms-licence-server-list',
    templateUrl: './licence-server-list.component.html',
})
export class RMSLicenceServerListComponent implements OnInit {

    _licenceKey: string;
    servers: RMSServer[];
    loading = false;

    constructor(private licenceSvc: RMSLicenceService, private serverSvc: RMSServerService, public ngxModalSvc: NgxSmartModalService) { }

    ngOnInit() {
        setTimeout(() => {
            this.ngxModalSvc.getModal('licenceServerListModal').onDataAdded.subscribe((data: string) => {
                console.log('Data', data);
                this._licenceKey = data;
                this.loadServers();
            });

            this.ngxModalSvc.getModal('editServerModal').onAnyCloseEvent.subscribe((data: string) => {
                console.log('Data', data);
                this.loadServers();
            });
        }, 0);

    }

    loadServers() {
        // load server list for key
        this.loading = true;
        if (this._licenceKey != null && this._licenceKey !== '') {
            this.serverSvc.getServersForLicence(this._licenceKey).then((data) => {
                this.servers = data;
                this.loading = false;
                $(document).ready(function () {
                    console.log('showing table');
                    (<any>$('#serverTable')).DataTable();
                });
            }, () => {
                this.loading = false;
            });
        } else {
            console.log('no key');
            this.servers = new Array<RMSServer>();
            this.loading = false;
        }
    }

    openServerModal(server: RMSServer) {
        this.ngxModalSvc.setModalData(server, 'editServerModal', true);
        this.ngxModalSvc.getModal('editServerModal').open();
    }
}
