import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { EMWDevice } from '../../../../shared/models/emw_device';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EMWDeviceService } from '../../../../shared/emw_device.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-emw-devices',
  templateUrl: './emw-devices.component.html',
  styleUrls: ['./emw-devices.component.css']
})
export class EMWDevicesComponent implements OnInit, OnDestroy {
  @ViewChild('filterBox') filterInput: ElementRef;
  devices: EMWDevice[];
  loading = true;
  updatedTime = 'N/A';
  totalSize = 0;
  offset = 0;
  limit = 50;
  filter = '';
  debounce = new BehaviorSubject<string>('');

  epicorUser = true;

  constructor(private deviceSvc: EMWDeviceService,
    public ngxModalSvc: NgxSmartModalService,
    private router: Router,
    private authSvc: AuthService) { }

  ngOnInit() {
    this.epicorUser = this.authSvc.isEpicorUser();
    this.limit = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : 50;
    this.debounce.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.offset = 0;
        this.loadDevices();
      });
  }

  ngOnDestroy() {
    this.debounce.unsubscribe();
  }

  filterChange() {
    this.debounce.next(this.filter);
  }

  paginate(options) {
    this.offset = options.offset;
    this.limit = options.limit;
    this.loadDevices();
  }

  loadDevices(): void {
    this.loading = true;
    this.deviceSvc.getDevices(this.offset, this.limit, this.filter).then((data) => {
      this.devices = data.devices;
      this.totalSize = data.total;
      this.updatedTime = this.deviceSvc.getUpdatedTimeString();
      this.loading = false;
      setTimeout(function () {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 50);
    }, () => {
      this.loading = false;
    });
  }

  openDeviceModal(device: EMWDevice) {
    this.router.navigate(['/emw/device', device.DeviceId]);
  }

  openAddDeviceModal() {
    this.router.navigate(['/emw/device', 'add']);
  }
}
