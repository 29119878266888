import { Component, OnInit } from '@angular/core';
import { EMWLicenceService } from '../../../../shared/emw_licence.service';
import { EMWDeviceService } from '../../../../shared/emw_device.service';
import { EMWDevice } from '../../../../shared/models/emw_device';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MessageService } from 'src/app/shared/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/shared/auth.service';
declare var $: any;

@Component({
    selector: 'app-emw-edit-device',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.css'],
    providers: [DatePipe]
})
export class EMWDeviceDetailsComponent implements OnInit {
    addDevice: boolean;
    device: EMWDevice;
    blockInput = false;
    loading = false;
    deviceIsValid = false;
    saving = false;
    licences: Array<string>;
    selectedLicence: string;
    deleting = false;
    valid = {
      licenceKey: false,
      deviceId: false
    };

    epicorUser = true;

    constructor(private licenceSvc: EMWLicenceService,
      private deviceSvc: EMWDeviceService,
      public ngxModalSvc: NgxSmartModalService,
      private messageSvc: MessageService,
      private route: ActivatedRoute,
      private router: Router,
      private datepipe: DatePipe,
      private authSvc: AuthService) { }

    ngOnInit() {
      this.epicorUser = this.authSvc.isEpicorUser();
      // this.loadLicenceList();
      this.route.params.subscribe(params => {
        if (params['deviceid'] !== 'add') {
          this.loadDevice(params['deviceid']);
        } else {
          if (params['licencekey'] && params['licencekey'] != null) {
            this.selectedLicence = params['licencekey'];
          }
          if (this.epicorUser) { this.router.navigate(['/emw/devices']); return; }
          this.loadDevice('');
        }
      });
    }

    loadDevice(deviceId: string) {
      this.addDevice = false;
      this.loading = true;
      if (deviceId && deviceId !== '') {
        this.deviceIsValid = false;
        this.deviceSvc.getDevice(deviceId).then((data) => {
          this.device = data;
          this.device.LastCheckDate = this.datepipe.transform(this.device.LastCheckDate, 'yyyy-MM-dd');
          this.device.DateRegistered = this.datepipe.transform(this.device.DateRegistered, 'yyyy-MM-dd');
          this.device.LastModified = this.datepipe.transform(this.device.LastModified, 'yyyy-MM-dd');
          this.device.DeregisteredDate = this.datepipe.transform(this.device.DeregisteredDate, 'yyyy-MM-dd');
          this.selectedLicence = this.device.LicenceKey;
          this.checkValid();
          this.loading = false;
        });
      } else {
        this.addDevice = true;
        this.device = new EMWDevice();
        this.device.Deregistered = false;
        this.checkValid();
        this.loading = false;

      }
    }

    checkValid(): void {
      if (this.device) {
        this.valid.licenceKey = this.selectedLicence != null && this.selectedLicence !== '';
        this.valid.deviceId = this.device.DeviceId != null && this.device.DeviceId !== '';
        this.deviceIsValid = this.valid.licenceKey && this.valid.deviceId;
      } else {
        this.deviceIsValid = false;
      }
    }

    selected(value: any) {
        console.log(value);
    }

    loadLicenceList() {
        this.loading = true;
        this.licences = Array<string>();
        this.licenceSvc.getLicences(0, 0, '').then((data) => {
            for (let i = 0; i < data.licences.length; i++) {
                this.licences.push(data.licences[i].LicenceKey);
            }
            this.licences = JSON.parse(JSON.stringify(this.licences));
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    saveDevice() {
      if (this.deviceIsValid && !this.epicorUser) {
        this.saving = true;
        this.blockInput = true;
        if (this.addDevice) {
          this.deviceSvc.addDevice(this.device.DeviceId, this.selectedLicence).then((response) => {
            if (!response.valid) {
              this.messageSvc.open('Error', 'Error saving device', response.message);
              this.saving = false;
              this.blockInput = false;
            } else {
              this.messageSvc.open('Success', 'Device Added', response.message);
              this.addDevice = false;
              this.saving = false;
              this.blockInput = false;
              this.router.navigate(['/emw/device', this.device.DeviceId]);
            }
          }, () => {
            this.saving = false;
            this.blockInput = false;
          });
        } else {
          if (this.device.LicenceKey !== this.selectedLicence) {
            this.saving = true;
            this.device.LicenceKey = this.selectedLicence;
            this.deviceSvc.updateDevice(this.device).then((response) => {
                this.saving = false;
                if (response.valid) {
                  this.messageSvc.open('Success', 'Device Saved', response.message);
                  this.saving = false;
                  this.blockInput = false;
                  this.router.navigate(['/emw/device', response.data.device.DeviceId]);
                } else {
                    this.messageSvc.open('Error', 'Error editing device', response.message);
                }
            }, () => {
                this.saving = false;
            });
          }
        }
      }
    }

    removeDevice() {
        if (confirm('Are you sure you want to remove this device?')) {
          this.deleting = true;
          this.ngxModalSvc.getModal('reasonForDeviceDelete').open();
        }
    }

    reasonEntered() {
      this.device.Deregistered = true;
      this.deviceSvc.deregisterDevice(this.device).then((response) => {
        this.deleting = false;
        this.router.navigate(['/emw/device', this.device.DeviceId]);
        this.loadDevice(this.device.DeviceId);
        this.ngxModalSvc.getModal('reasonForDeviceDelete').close();
      }, () => {
          this.deleting = false;
      });
    }

    back() {
      if (this.epicorUser) {
        this.router.navigate(['/emw/licence', this.device.LicenceKey]);
      } else {
        this.router.navigate(['/emw/devices']);
      }
    }
}
