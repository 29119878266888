import { Component, OnInit, Input } from '@angular/core';
import { EMWLicenceService } from '../../../../../shared/emw_licence.service';
import { EMWDeviceService } from '../../../../../shared/emw_device.service';
import { MessageService } from '../../../../../shared/message.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
declare var $: any;

@Component({
    selector: 'app-emw-add-device',
    templateUrl: './add-device.component.html',
    styleUrls: ['./add-device.component.css']
})
export class EMWAddDeviceComponent implements OnInit {
    loading = false;
    deviceId: string;
    licences: Array<string>;
    selectedLicence: string;
    saving = false;
    deleting = false;
    validDeviceId = false;

    constructor(private licenceSvc: EMWLicenceService, private deviceSvc: EMWDeviceService, public ngxModalSvc: NgxSmartModalService,
        private messageSvc: MessageService) { }

    ngOnInit() {
        this.ngxModalSvc.getModal('addDeviceModal').onDataAdded.subscribe((data: string) => {
            console.log('Data', data);
            this.deviceId = '';
            this.selectedLicence = data;
            this.loadLicenceList();
        });
    }

    loadLicenceList() {
        this.loading = true;
        this.licences = Array<string>();
        this.licenceSvc.getLicences(0, 0, '').then((data) => {
            console.log('Data from get licences:', data);
            for (let i = 0; i < data.licences.length; i++) {
                this.licences.push(data.licences[i].licenceKey);
            }
            console.log(this.licences);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    addDevice() {
        if (this.selectedLicence !== '') {
            this.saving = true;
            this.deviceSvc.addDevice(this.deviceId, this.selectedLicence).then((response) => {
                console.log(response);
                this.saving = false;
                if (response.valid) {
                    this.ngxModalSvc.getModal('addDeviceModal').close();
                } else {
                    this.messageSvc.open('Error', 'Error adding device', response.message);
                }
            }, (response) => {
                console.log(response);
                this.saving = false;
            });
        }
    }

    checkValid(): void {
        const number = new RegExp(/\w+/);
        this.validDeviceId = true;
        this.validDeviceId = (this.validDeviceId && this.deviceId.length > 0 && this.deviceId.length < 25);
        for (let i = 0; i < this.deviceId.length; i++) {
            this.validDeviceId = (this.validDeviceId && number.test(this.deviceId[i]));
        }
    }
}
