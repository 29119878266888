import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../../../../../shared/message.service';
import { EMWLicence } from '../../../../../shared/models/emw_licence';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RMSLicenceService } from 'src/app/shared/rms_licence.service';
import { RMSLicence } from 'src/app/shared/models/rms_licence';

@Component({
    selector: 'app-rms-licence-details',
    templateUrl: './licence-details.component.html',
    styleUrls: ['./licence-details.component.css']
})
export class RMSLicenceDetailsComponent implements OnInit {
    addLicence: boolean;
    licence: RMSLicence;
    deviceLicenceKey = '';
    blockInput = false;
    loading = false;
    licenceIsValid = false;
    canDelete = false;
    saving = false;
    deleting = false;
    valid = {
        licenceKey: false,
        companyName: false,
        expiryDate: false,
        maxNumOfCompanies: false,
        maxNumOfUsers: false,
        ourRef: false,
        customerRef: false,
        maxNumOfInstalls: false
    };

    constructor(private licenceSvc: RMSLicenceService, public ngxModalSvc: NgxSmartModalService,
        private messageSvc: MessageService) { }

    ngOnInit() {
        setTimeout(() => {
            this.ngxModalSvc.getModal('licenceDetailsModal').onDataAdded.subscribe((licenceKey: string) => {
                this.loadLicence(licenceKey);
            });
        }, 0);

    }

    loadLicence(licenceKey: string) {
        this.addLicence = false;
        console.log('Modal openend:', licenceKey);
        this.loading = true;
        if (licenceKey && licenceKey !== '') {
            this.licenceIsValid = false;
            this.licenceSvc.getLicence(licenceKey).then((data) => {
                console.log('licence details data:', data);
                this.licence = data;
                this.checkValid();
                console.log(this.licence);
                this.canDelete = true;
                this.loading = false;
                if (this.licence.LicenceKey == null || this.licence.LicenceKey === '') {
                    this.loading = false;
                    this.messageSvc.open('Error', 'Licence does not exist', 'This licence key cannot be found. Is your data up-to-date?');
                    this.ngxModalSvc.getModal('licenceDetailsModal').close();
                }
                console.log(this.addLicence);
            }, (response) => {
                this.loading = false;
                console.log(response);
                this.messageSvc.open('Error', 'Licence does not exist', 'This licence key cannot be found. Is your data up-to-date?');
                this.ngxModalSvc.getModal('licenceDetailsModal').close();
                // show modal service
            });
        } else {
            console.log('creating new licence');
            this.addLicence = true;
            this.licence = new RMSLicence();
            this.licence.LicenceKey = '';
            this.licence.CompanyName = '';
            this.licence.ExpiryDate = '';
            this.licence.IsActive = true;
            this.licence.MaxNumOfCompanies = 0;
            this.licence.MaxNumOfUsers = 0;
            this.licence.MaxNumOfInstalls = 0;
            this.licence.OurRef = '';
            this.licence.CustomerRef = '';
            this.licence.Notes = '';
            this.checkValid();
            this.loading = false;
        }
    }

    getLastUpdated(): string {
        return 'Updated today at ';
    }

    checkValid(): void {
        if (this.licence) {
            const expiryDateRegExp = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
            const licenceKeyRegExp = new RegExp(/\w+/);
            this.valid.licenceKey = (this.licence.LicenceKey !== '' && this.licence.LicenceKey.length < 25);
            for (let i = 0; i < this.licence.LicenceKey.length; i++) {
                this.valid.licenceKey = (this.valid.licenceKey && licenceKeyRegExp.test(this.licence.LicenceKey[i]));
            }
            this.valid.companyName = (this.licence.CompanyName !== '' && this.licence.CompanyName.length < 50);
            this.valid.expiryDate = (this.licence.ExpiryDate !== '' && expiryDateRegExp.test(this.licence.ExpiryDate));
            this.valid.maxNumOfCompanies = (typeof this.licence.MaxNumOfCompanies === 'number' &&
                this.licence.MaxNumOfCompanies > 0 && this.licence.MaxNumOfCompanies < 10000);
            this.valid.maxNumOfUsers = (typeof this.licence.MaxNumOfUsers === 'number' &&
                this.licence.MaxNumOfUsers > 0 && this.licence.MaxNumOfUsers < 10000);
            this.valid.ourRef = (this.licence.OurRef.length <= 30);
            this.valid.customerRef = (this.licence.CustomerRef.length <= 30);
            this.valid.maxNumOfInstalls = (this.licence.MaxNumOfInstalls < 10000);
            this.licenceIsValid = this.valid.licenceKey && this.valid.companyName &&
                this.valid.expiryDate && this.valid.maxNumOfCompanies &&
                this.valid.maxNumOfUsers && this.valid.ourRef && this.valid.customerRef;
        } else {
            this.licenceIsValid = false;
        }
    }

    saveLicence(): void {
        if (this.licenceIsValid) {
            this.saving = true;
            this.blockInput = true;
            if (this.addLicence) {
                this.licenceSvc.addLicence(this.licence).then((response) => {
                    if (!response.valid) {
                        this.messageSvc.open('Error', 'Error saving licence', response.message);
                        this.saving = false;
                        this.blockInput = false;
                    } else {
                        this.messageSvc.open('Success', 'Licence Added', response.message);
                        this.addLicence = false;
                        this.saving = false;
                        this.blockInput = false;
                        this.loadLicence(this.licence.LicenceKey);
                    }
                }, () => {
                    console.log('Could not add licence');
                    this.saving = false;
                    this.blockInput = false;
                });
            } else {
              console.log('save licence', this.licence);
                this.licenceSvc.updateLicence(this.licence).then((response) => {
                    if (!response.valid) {
                      this.messageSvc.open('Error', 'Error Saving Licence', response.message);
                    }
                    this.saving = false;
                    this.blockInput = false;
                    this.loadLicence(this.licence.LicenceKey);
                }, () => {
                    console.log('Could not update licence');
                    this.saving = false;
                    this.blockInput = false;
                });
            }
        }
    }

    deleteLicence(): void {
        if (this.canDelete && confirm('Are you sure you want to delete this licence?')) {
            this.blockInput = true;
            this.deleting = true;
            this.licenceSvc.removeLicence(this.licence.LicenceKey).then((data) => {
                console.log('removed licence');
                this.deleting = false;
                this.blockInput = false;
                this.ngxModalSvc.getModal('licenceDetailsModal').close();
            }, () => {
                this.deleting = false;
                this.blockInput = false;
                console.log('Could not remove licence');
            });
        }
    }

    openServerListModal() {
        console.log('Resetting data');
        this.ngxModalSvc.setModalData(this.licence.LicenceKey, 'licenceServerListModal', true);
        this.ngxModalSvc.getModal('licenceServerListModal').open();
    }
}
