import { Component, OnInit } from '@angular/core';
import { RMSLicenceService } from '../../../../../shared/rms_licence.service';
import { RMSServerService } from '../../../../../shared/rms_server.service';
import { RMSServer } from '../../../../../shared/models/rms_server';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MessageService } from '../../../../../shared/message.service';
declare var $: any;

@Component({
  selector: 'app-rms-edit-server',
  templateUrl: './edit-server.component.html'
})
export class RMSEditServerComponent implements OnInit {
  loading = false;
  server: RMSServer;
  licences: Array<string>;
  selectedLicence: string;
  saving = false;
  deleting = false;

  constructor(private licenceSvc: RMSLicenceService, private serverSvc: RMSServerService,
    public ngxModalSvc: NgxSmartModalService, private messageSvc: MessageService) { }

  ngOnInit() {
      this.ngxModalSvc.getModal('editServerModal').onDataAdded.subscribe((data: RMSServer) => {
          console.log('Data', data);
          this.server = data;
          this.selectedLicence = data.LicenceKey;
          this.loadLicenceList();
      });
  }

  selected(value: any) {
      console.log(value);
  }

  loadLicenceList() {
      this.loading = true;
      this.licences = Array<string>();
      this.licenceSvc.getLicences(0, 0, '').then((data) => {
          console.log('Data from get licences:', data);
          for (let i = 0; i < data.licences.length; i++) {
              this.licences.push(data.licences[i].licenceKey);
          }
          console.log(this.licences);
          this.loading = false;
      }, () => {
          this.loading = false;
      });
  }

  saveServer() {
      if (this.server.LicenceKey !== this.selectedLicence) {
          this.saving = true;
          this.server.LicenceKey = this.selectedLicence;
          this.serverSvc.updateServer(this.server).then((response) => {
              console.log(response);
              this.saving = false;
              if (response.valid) {
                  this.ngxModalSvc.getModal('editServerModal').close();
              } else {
                  this.messageSvc.open('Error', 'Error editing server', response.message);
              }
          }, () => {
              this.saving = false;
          });
      }
  }

  removeServer() {
      if (confirm('Are you sure you want to remove this server?')) {
          this.deleting = true;
          this.serverSvc.deleteServer(this.server).then((response) => {
              console.log(response);
              this.deleting = false;
              this.ngxModalSvc.getModal('editServerModal').close();
          }, () => {
              this.deleting = false;
          });
      }
  }
}
