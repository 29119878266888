import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class CheckService {
    serverUrl = environment.apiUrl + 'check';

    constructor(private http: HttpClient, private errorSvc: ErrorService) { }

    getLicenceDetails(licence): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?licencekey=' + licence,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' })
                .subscribe((data: any) => {
                    if (data.body.valid) {
                        resolve(data.body);
                    } else {
                        reject(data.body);
                    }
                }, err => {
                    this.errorSvc.handleError(err);
                    reject();
                });
        });
    }

    getUpdatedTimeString(): string {
        const currentTime = new Date();
        return currentTime.toLocaleTimeString('en-AU');
    }
}
