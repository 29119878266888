import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent implements OnInit {
  type: string;
  title: string;
  message: string;
  constructor(public ngxModalSvc: NgxSmartModalService) { }

  ngOnInit() {
    setTimeout(() => {
      this.ngxModalSvc.getModal('messageModal').onDataAdded.subscribe((data: any) => {
        this.title = data.title;
        this.message = data.message;
        this.type = data.type;
      });
    }, 0);
  }
}
