import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMWDevice } from './models/emw_device';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { ErrorService } from './error.service';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class EMWDeviceService {
    serverUrl = environment.apiUrl + 'emw/device';
    constructor(private http: HttpClient, private errorSvc: ErrorService, private messageSvc: MessageService) { }

    getDevicesForLicence(licenceKey: String): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?licencekey=' + licenceKey,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
                    console.log(data.body);
                    const devices = new Array<EMWDevice>();
                    data.body.data.forEach(element => {
                        let device = new EMWDevice();
                        device = element;
                        devices.push(device);
                    });
                    resolve(devices);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    getDevices(offset, limit, filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.serverUrl + '?offset=' + offset + '&limit=' + limit + '&filter=' + filter,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
                    const devices = new Array<EMWDevice>();
                    data.body.data.devices.forEach(element => {
                        let device = new EMWDevice();
                        device = element;
                        devices.push(device);
                    });
                    resolve({ total: data.body.data.total, devices: devices });
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    deregisterDevice(device: EMWDevice): Promise<any> {
      const devicePayload = device;
      return new Promise((resolve, reject) => {
        this.http.put(this.serverUrl, devicePayload,
          { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
            resolve(response.body);
          }, err => {
            this.errorSvc.handleError(err);
            reject(err);
          });
      });
    }

    updateDevice(device: EMWDevice): Promise<any> {
        const devicePayload = {
            data: device
        };
        return new Promise((resolve, reject) => {
            this.http.put(this.serverUrl, devicePayload,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
                    resolve(response.body);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    deleteDevice(device: EMWDevice): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.serverUrl + '?deviceid=' + device.DeviceId,
            { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
                    resolve(response.body);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    addDevice(deviceId: string, licenceKey: string): Promise<any> {
        const devicePayload = {
            data: {
                deviceId: deviceId,
                licenceKey: licenceKey
            }
        };
        return new Promise((resolve, reject) => {
            this.http.post(this.serverUrl, devicePayload,
                { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
                    resolve(response.body);
                }, err => {
                    this.errorSvc.handleError(err);
                    reject(err);
                });
        });
    }

    getDevice(deviceId): Promise<any> {
      return new Promise((resolve, reject) => {
        this.http.get(this.serverUrl + '?deviceid=' + deviceId,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
          if (response.body.valid) {
            let device = new EMWDevice();
            device = response.body.data;
            resolve(device);
          } else {
            reject(response.body);
          }
        }, err => {
          this.errorSvc.handleError(err);
          reject(err);
        });
      });
    }

    getUpdatedTimeString(): string {
        const currentTime = new Date();
        return currentTime.toLocaleTimeString('en-AU');
    }
}
