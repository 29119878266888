import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
    @Input() set pageNumber(value) {
        this._pageNumber = value;
        this.start();
    }
    @Input() set pageSize(value) {
        this._pageSize = value;
        localStorage.setItem('PageLimit', value);
        this.start();
    }
    @Input() set totalSize(value) {
        console.log('setting total size:', value);
        this._totalSize = value;
        this._currentPage = 1;
        this.calculateMaxPageSize();
    }
    @Output() navigate: EventEmitter<object> = new EventEmitter<object>();

    _totalSize = 0;
    _pageNumber = 0;
    _pageSize = 50;
    _currentPage = 0;
    _maxPage = 1;

    constructor() { }

    ngOnInit() {
        this._pageSize = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : this._pageSize;
    }

    calculateMaxPageSize() {
        this._maxPage = Math.ceil(this._totalSize / this._pageSize);
        if (this._maxPage <= 0) {
            this._maxPage = 1;
        }
    }

    updatePageSize() {
        this.calculateMaxPageSize();
        this._currentPage = 1;
        localStorage.setItem('PageLimit', this._pageSize.toString());
        this.navigate.emit({ offset: 0, limit: this._pageSize });
    }

    start() {
        this._currentPage = 1;
        this.navigate.emit({ offset: 0, limit: this._pageSize });
    }

    previous() {
        if (this._currentPage > 1) {
            this._currentPage--;
            this.navigate.emit({ offset: (this._currentPage - 1) * this._pageSize, limit: this._pageSize });
        }
    }

    next() {
        if (this._currentPage < this._maxPage) {
            this._currentPage++;
            this.navigate.emit({ offset: (this._currentPage - 1) * this._pageSize, limit: this._pageSize });
        }
    }

    end() {
        if (this._currentPage !== this._maxPage) {
            this._currentPage = this._maxPage;
            this.navigate.emit({ offset: (this._currentPage - 1) * this._pageSize, limit: this._pageSize });
        }
    }
}
