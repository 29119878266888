import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    if (this.auth.isEpicorUser()) {
      localStorage.setItem('product', 'EMW');
    }
    if (this.auth.isEpicorUser() && route.routeConfig.path === 'dashboard') {
      this.router.navigate(['/emw/licences']);
      return false;
    }
    if (this.auth.isEpicorUser() && route.data['internalOnly']) {
      return false;
    }
    return true;
  }

  public getRedirectPage(): string {
    if (this.auth.isEpicorUser) {
      return '/emw/licences';
    } else {
      return '/dashboard';
    }
  }
}
