import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EMWLicencesComponent } from './components/emw/licences/list/emw-licences.component';
import { EMWDevicesComponent } from './components/emw/devices/list/emw-devices.component';
import { EMWDeviceDetailsComponent } from './components/emw/devices/detail/device-details.component';
import { RMSServersComponent } from './components/rms/servers/rms-servers.component';
import { LogsComponent } from './components/logs/logs.component';
import { LicenceCheckerComponent } from './components/licence-checker/licence-checker.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService as AuthGuard } from './shared/auth-guard.service';
import { RMSLicencesComponent } from './components/rms/licences/rms-licences.component';
import { EMWLicenceDetailsComponent } from './components/emw/licences/detail/licence-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard], data: { internalOnly: false } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { internalOnly: true }},
  { path: 'emw/licences', component: EMWLicencesComponent, canActivate: [AuthGuard], data: { internalOnly: false } },
  { path: 'emw/licence/:licencekey', component: EMWLicenceDetailsComponent, canActivate: [AuthGuard], data: { internalOnly: false } },
  { path: 'rms/licences', component: RMSLicencesComponent, canActivate: [AuthGuard], data: { internalOnly: true } },
  { path: 'emw/devices', component: EMWDevicesComponent, canActivate: [AuthGuard], data: { internalOnly: true } },
  { path: 'emw/device/:deviceid', component: EMWDeviceDetailsComponent, canActivate: [AuthGuard], data: { internalOnly: false } },
  { path: 'emw/device/:deviceid/:licencekey', component: EMWDeviceDetailsComponent, canActivate: [AuthGuard], data: { internalOnly: false} },
  { path: 'rms/servers', component: RMSServersComponent, canActivate: [AuthGuard], data: { internalOnly: true } },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard], data: { internalOnly: true } },
  { path: 'licence-checker', component: LicenceCheckerComponent, canActivate: [AuthGuard], data: { internalOnly: false } },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  providers: [AuthGuard],
  imports: [ RouterModule.forRoot(routes) ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {}
  }
