export class RMSLicence {
    LicenceKey: string;
    CompanyName: string;
    ExpiryDate: string;
    MaxNumOfCompanies: number;
    MaxNumOfUsers: number;
    IsActive: boolean;
    OurRef: string;
    CustomerRef: string;
    Notes: string;
    NumOfInstalls: number;
    MaxNumOfInstalls: number;
}
