export class EMWLicence {
  LicenceKey: string;
  CompanyName: string;
  // expiryDate: string;
  IsActive: boolean;
  MaxNumOfDevices: number;
  PreventDelicensing: boolean;
  PreventManualLicensing: boolean;
  InternalRef: string = "";
  InternalCustomerRef: string = "";
  Notes: string = "";
  InternalNotes: string = "";
  DateAdded: string = "";
  LastModified: string = "";
  LastModifiedBy: string = "";
  Epicor: boolean;
  Deleted: boolean;
  EpicorSiteID: number;
  CheckFreqDays: number;
  MaintenanceExpiryDate: string = "";
  ExpiryDate: string = "";
  Partner: boolean = false;
  LicenceType: string = "Saas";
  AccessLevel: string = "";
  HiddenSettings: HiddenSettings;
}

export class HiddenSettings {
  JobQuickComplete: boolean = false;
  ReusablesReturnProcess: boolean = false;
  AllowSSLErrors: boolean = false;
  HasAlphaAccess: boolean = false;
  HasBetaAccess: boolean = false;
  LabelPlacardPrint: boolean = false;
  CSGEmployeeIdSuffix: boolean = false;
  PrivateCloudPrinting: boolean = false;
  HeatAttributes: boolean = false;
  RouteOptimisationOverride: boolean = false;
  MoveInventoryPrintTag: boolean = false;
  IssueMaterialRapidScan: boolean = false;
  PbsIssueReturnCustomFields: boolean = false;
  EnableTelemetry: boolean = false;
  ConfigurableSettingsScopes: boolean = false;
  MovePcidToBin: boolean = false;
  MoveBinToBin: boolean = false;
}
