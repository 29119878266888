import { Component, OnInit, OnDestroy } from '@angular/core';
import { CheckService } from 'src/app/shared/check.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-licence-checker',
  templateUrl: './licence-checker.component.html',
  styleUrls: ['./licence-checker.component.css']
})
export class LicenceCheckerComponent implements OnInit, OnDestroy {
  licenceToCheck = '';
  loading = false;
  searched = false;
  numOfLicences = 0;
  licenceData = { emw: null, rms: null };
  epicorUser = true;

  constructor(private checkSvc: CheckService, private authSvc: AuthService) { }

  ngOnInit() {
    this.epicorUser = this.authSvc.isEpicorUser();
  }

  ngOnDestroy() {
  }

  search() {
    console.log('searching...');
    this.loading = true;
    this.checkSvc.getLicenceDetails(this.licenceToCheck).then((response) => {
      console.log(response);
      this.licenceData = response.data;
      this.numOfLicences = 0;
      if (response.data.emw != null) { this.numOfLicences++; }
      if (response.data.rms != null) { this.numOfLicences++; }
      this.loading = false;
      this.searched = true;
    }, () => {
      this.loading = false;
      this.searched = true;
    });
    // Find out what areas the licence belongs to
    // Display results

    // Licence type
    // Expiry date
    // Max number of devices allowed, and number registered (if EMW)
    // Company registered to
  }
}
