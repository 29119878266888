import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EMWLicenceService } from '../../../../shared/emw_licence.service';
import { EMWDeviceService } from '../../../../shared/emw_device.service';
import { MessageService } from '../../../../shared/message.service';
import { EMWLicence, HiddenSettings } from '../../../../shared/models/emw_licence';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/shared/auth.service';
declare var $: any;

@Component({
    selector: 'app-emw-licence-details',
    templateUrl: './licence-details.component.html',
    styleUrls: ['./licence-details.component.css'],
    providers: [DatePipe]
})
export class EMWLicenceDetailsComponent implements OnInit {
    addLicence: boolean;
    licence: EMWLicence;
    deviceLicenceKey = '';
    blockInput = false;
    loading = false;
    licenceIsValid = false;
    canDelete = false;
    saving = false;
    deleting = false;
    randomString = '';
    valid = {
      epicorSiteId: false,
      companyName: false,
      maxNumOfDevices: false,
      checkFreqDays: false,
      maintenanceExpiryDate: false,
      expiryDate: false,
      internalRef: false,
      internalCustomerRef: false
    };

    epicorUser = true;

    constructor(private licenceSvc: EMWLicenceService,
      private deviceSvc: EMWDeviceService,
      public ngxModalSvc: NgxSmartModalService,
      private messageSvc: MessageService,
      private route: ActivatedRoute,
      private datepipe: DatePipe,
      private router: Router,
      private authSvc: AuthService) { }

    ngOnInit() {
      this.epicorUser = this.authSvc.isEpicorUser();
      this.route.params.subscribe(params => {
        if (params['licencekey'] !== 'add') {
          this.loadLicence(params['licencekey']);
        } else {
          this.loadLicence('');
        }
      });
    }

    loadLicence(licenceKey: string) {
        this.addLicence = false;
        this.loading = true;
        if (licenceKey && licenceKey !== '') {
            this.licenceIsValid = false;
            this.licenceSvc.getLicence(licenceKey).then((data) => {
                this.licence = data;
                this.licence.DateAdded = this.datepipe.transform(this.licence.DateAdded, 'yyyy-MM-dd');
                this.licence.MaintenanceExpiryDate =
                  this.licence.MaintenanceExpiryDate != null
                    ? this.datepipe.transform(
                        this.licence.MaintenanceExpiryDate,
                        'yyyy-MM-dd'
                      )
                    : null;
                this.licence.ExpiryDate =
                  this.licence.ExpiryDate != null
                    ? this.datepipe.transform(
                        this.licence.ExpiryDate,
                        'yyyy-MM-dd'
                      )
                    : null;
                this.checkValid();
                if (this.licence.LicenceKey != null && this.licence.LicenceKey !== '') {
                  this.deviceSvc.getDevicesForLicence(this.licence.LicenceKey).then((deviceList) => {
                      this.canDelete = deviceList.length === 0;
                      this.loading = false;
                  }, () => {
                      this.loading = false;
                  });
                } else {
                    this.loading = false;
                    this.messageSvc.open('Error', 'Licence does not exist', 'This licence key cannot be found. Is your data up-to-date?');
                    this.router.navigate(['/emw/licences']);
                }
            }, (response) => {
                this.loading = false;
                this.messageSvc.open('Error', 'Licence does not exist', 'This licence key cannot be found. Is your data up-to-date?');
                this.router.navigate(['/emw/licences']);
            });

        } else {
            this.addLicence = true;
            this.licence = new EMWLicence();
            this.licence.ExpiryDate = null;
            this.licence.LicenceType = 'Saas';
            this.licence.AccessLevel = '';
            this.valid.expiryDate = true;
            this.licence.LicenceKey = null;
            // this.licence.EpicorSiteID = 0;
            this.licence.CompanyName = '';
            this.licence.MaxNumOfDevices = 0;
            this.licence.CheckFreqDays = 7;
            this.licence.MaintenanceExpiryDate = '';
            this.licence.Epicor = false;
            this.licence.IsActive = true;
            this.licence.PreventDelicensing = false;
            this.licence.PreventManualLicensing = false;
            this.licence.Notes = '';
            this.licence.InternalRef = '';
            this.licence.InternalCustomerRef = '';
            this.licence.DateAdded = this.datepipe.transform(Date.now(), 'yyyy-MM-dd');
            this.licence.InternalNotes = '';
            this.licence.HiddenSettings = new HiddenSettings();

            this.checkValid();
            this.loading = false;
        }
    }

    getLastUpdated(): string {
        return 'Updated today at ';
    }

    accessLevelChanged(accessLevel) {
      this.licence.AccessLevel = accessLevel;
      this.checkValid();
    }

    licenceTypeChanged(licenceType) {
      this.licence.LicenceType = licenceType;
      this.checkValid();
    }

    checkValid(): void {
        if (this.licence) {
            const expiryDateRegExp = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
            this.valid.epicorSiteId =
              this.licence.EpicorSiteID != null &&
              this.licence.EpicorSiteID > 0 &&
              this.licence.EpicorSiteID <= 2147483647;
            this.valid.companyName = (this.licence.CompanyName !== '' && this.licence.CompanyName.length < 50);
            this.valid.maxNumOfDevices = (typeof this.licence.MaxNumOfDevices === 'number' &&
                this.licence.MaxNumOfDevices > 0 && this.licence.MaxNumOfDevices < 10000);
            this.valid.checkFreqDays = this.epicorUser || this.licence.CheckFreqDays > 0;
            this.valid.maintenanceExpiryDate = (this.licence.MaintenanceExpiryDate !== ''
            && expiryDateRegExp.test(this.licence.MaintenanceExpiryDate))
            || this.licence.MaintenanceExpiryDate === '' || this.licence.MaintenanceExpiryDate == null;
            this.valid.expiryDate = ((this.licence.LicenceType === 'Trial' || this.licence.LicenceType === 'Saas' )
            && this.licence.ExpiryDate !== '' && expiryDateRegExp.test(this.licence.ExpiryDate))
            || this.licence.LicenceType === 'Perpetual';
            this.valid.internalRef = this.licence.InternalRef == null || (this.licence.InternalRef.length <= 30);
            this.valid.internalCustomerRef = this.licence.InternalCustomerRef == null || (this.licence.InternalCustomerRef.length <= 30);
            this.licenceIsValid = this.valid.epicorSiteId && this.valid.companyName && this.valid.maxNumOfDevices
            && this.valid.checkFreqDays && this.valid.maintenanceExpiryDate && this.valid.expiryDate
            && this.valid.internalRef && this.valid.internalCustomerRef;
            if (this.licence.LicenceType === 'Perpetial') { this.licence.ExpiryDate = ''; }
            if (this.licence.LicenceType === 'Trial' || this.licence.LicenceType === 'Saas') { this.licence.MaintenanceExpiryDate = ''; }
        } else {
            this.licenceIsValid = false;
        }
    }

    saveLicence(): void {
        if (this.licenceIsValid) {
            this.saving = true;
            this.blockInput = true;
            if ((this.licence.Epicor && !this.epicorUser && confirm('You are modifying an Epicor licence. Do you wish to continue?'))
            || (!this.licence.Epicor && !this.epicorUser) || this.epicorUser) {
              if (this.addLicence) {
                this.licenceSvc.addLicence(this.licence).then((response) => {
                    if (!response.valid) {
                        this.messageSvc.open('Error', 'Error saving licence', response.message);
                        this.saving = false;
                        this.blockInput = false;
                    } else {
                        this.messageSvc.open(
                          'Success',
                          'Licence Added',
                          response.message +
                            ' Licence Key: ' +
                            response.data.licence.LicenceKey
                        );
                        this.addLicence = false;
                        this.saving = false;
                        this.blockInput = false;
                        this.router.navigate(['/emw/licence', response.data.licence.LicenceKey]);
                        // this.loadLicence(this.licence.LicenceKey);
                    }
                }, () => {
                    console.log('Could not add licence');
                    this.saving = false;
                    this.blockInput = false;
                });
            } else {
                this.licenceSvc.updateLicence(this.licence).then((response) => {
                    if (!response.valid) {
                      this.messageSvc.open('Error', 'Error Saving Licence', response.message);
                      this.saving = false;
                      this.blockInput = false;
                    } else {
                      this.messageSvc.open('Success', 'Licence Saved', response.message);
                      this.saving = false;
                      this.blockInput = false;
                      this.router.navigate(['/emw/licence', this.licence.LicenceKey]);
                      // this.loadLicence(this.licence.LicenceKey);
                    }
                }, () => {
                    console.log('Could not update licence');
                    this.saving = false;
                    this.blockInput = false;
                });
            }
          } else {
            this.saving = false;
            this.blockInput = false;
          }
        }
    }

    deleteLicence(): void {
        if (this.canDelete && confirm('Are you sure you want to delete this licence?')) {
            this.blockInput = true;
            this.deleting = true;
            this.licenceSvc.removeLicence(this.licence.LicenceKey).then((data) => {
                console.log('removed licence');
                this.deleting = false;
                this.blockInput = false;
                this.messageSvc.open('Success', 'Licence Deleted', data.message);
                this.router.navigate(['/emw/licences']);
            }, () => {
                this.deleting = false;
                this.blockInput = false;
                console.log('Could not remove licence');
            });
        }
    }

    back() {
      this.router.navigate(['/emw/licences']);
    }
    openDeviceListModal() {
        console.log('Resetting data');
        this.ngxModalSvc.setModalData(this.licence.LicenceKey, 'licenceDeviceListModal', true);
        this.ngxModalSvc.getModal('licenceDeviceListModal').open();
    }
}
