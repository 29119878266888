import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMWLicence, HiddenSettings } from './models/emw_licence';
import { environment } from '../../environments/environment';
import { ErrorService } from './error.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class EMWLicenceService {
  serverUrl = environment.apiUrl + 'emw/licence';

  constructor(private http: HttpClient, private errorSvc: ErrorService) { }

  getLicences(offset, limit, filter): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.serverUrl + '?offset=' + offset + '&limit=' + limit + '&filter=' + filter,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
          console.log(data.body);
          const licences = new Array<EMWLicence>();
          data.body.data.licences.forEach(element => {
            let licence = new EMWLicence();
            licence = element;
            licences.push(licence);

          });
          resolve({ total: data.body.data.total, licences: licences });
        }, err => {
          this.errorSvc.handleError(err);
          reject('licences');
        });
    });
  }

  getLicence(licenceKey): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.serverUrl + '?licencekey=' + licenceKey,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((response: any) => {
          console.log(response.body);
          if (response.body.valid) {
            let licence = new EMWLicence();
            licence = response.body.data;
            if (licence.HiddenSettings == null) {
              licence.HiddenSettings = new HiddenSettings();
            } else {
              licence.HiddenSettings = JSON.parse((<any>licence).HiddenSettings);
            }
            resolve(licence);
          } else {
            reject(response.body);
          }

        }, err => {
          this.errorSvc.handleError(err);
          reject(err);
        });
    });
  }

  removeLicence(licenceKey: String): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.delete(this.serverUrl + '?licencekey=' + licenceKey,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
          console.log(data.body);
          resolve(data.body);

        }, err => {
          this.errorSvc.handleError(err);
          reject('devicesFor' + licenceKey);
        });
    });
  }

  addLicence(selectedLicence: EMWLicence): Promise<any> {

    const licencePayload = {
      data: selectedLicence
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.serverUrl, licencePayload,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
          resolve(data.body);
        }, err => {
          this.errorSvc.handleError(err);
          reject(err);
        });
    });
  }

  updateLicence(selectedLicence: EMWLicence): Promise<any> {
    const licencePayload = {
      data: selectedLicence
    };

    return new Promise((resolve, reject) => {
      this.http.put(this.serverUrl, licencePayload,
        { headers: { 'Credentials': btoa(localStorage.getItem('authToken')) }, observe: 'response' }).subscribe((data: any) => {
          resolve(data.body);
        }, err => {
          this.errorSvc.handleError(err);
          reject(err);
        });
    });
  }

  getUpdatedTimeString(): string {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString('en-AU');
  }
}
