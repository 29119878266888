import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { RMSLicence } from '../../../shared/models/rms_licence';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RMSLicenceService } from 'src/app/shared/rms_licence.service';

@Component({
  selector: 'app-rms-licences',
  templateUrl: './rms-licences.component.html',
  styleUrls: ['./rms-licences.component.css']
})
export class RMSLicencesComponent implements OnInit, OnDestroy {
  @ViewChild('filterBox') filterInput: ElementRef;
  licences: RMSLicence[];
  selectedLicence: RMSLicence;
  loading = true;
  updatedTime = 'N/A';
  totalSize = 0;
  offset = 0;
  limit = 50;
  filter = '';
  debounce = new BehaviorSubject<string>('');

  constructor(private licenceSvc: RMSLicenceService, private ngxModalSvc: NgxSmartModalService) { }

  ngOnInit() {
    this.limit = localStorage.getItem('PageLimit') != null ? parseInt(localStorage.getItem('PageLimit'), 10) : 50;
    this.debounce.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.refreshList();
      });
  }

  ngOnDestroy() {
    this.debounce.unsubscribe();
  }

  filterChange() {
    this.debounce.next(this.filter);
  }

  paginate(options) {
    console.log(options);
    this.offset = options.offset;
    this.limit = options.limit;
    this.loadLicences();
  }

  refreshList() {
    this.offset = 0;
    this.loadLicences();
  }

  loadLicences(): void {
    this.loading = true;
    this.licenceSvc.getLicences(this.offset, this.limit, this.filter).then((data) => {
      this.licences = data.licences;
      this.totalSize = data.total;
      console.log(data.total);
      this.updatedTime = this.licenceSvc.getUpdatedTimeString();
      this.loading = false;
      setTimeout(function () {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 50);
    }, () => {
      this.loading = false;
    });
  }

  openLicenceModal(licence: RMSLicence): void {
    this.ngxModalSvc.setModalData(licence.LicenceKey, 'licenceDetailsModal', true);
    this.ngxModalSvc.getModal('licenceDetailsModal').open();
  }

  addLicence(): void {
    this.ngxModalSvc.setModalData(null, 'licenceDetailsModal', true);
    this.ngxModalSvc.getModal('licenceDetailsModal').open();
  }
}
