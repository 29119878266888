import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userId = '';
  userPassword = '';
  loading: boolean;
  invalid: boolean;

  constructor(private auth: AuthService, public router: Router) { }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      console.log('not authenticated');
      this.auth.logout();
      location.reload();
    }

    this.userId = localStorage.getItem('lastUsedUserId') != null ? localStorage.getItem('lastUsedUserId') : '';
  }

  login() {
    this.loading = true;
    this.invalid = false;
    this.auth.login(this.userId, this.userPassword).then(() => {
      console.log('Login success');
      localStorage.setItem('lastUsedUserId', this.userId);
      this.router.navigate(['dashboard']);
      this.loading = false;
    }, () => {
      console.log('Login failure');
      this.loading = false;
      this.invalid = true;
    });
  }

}
