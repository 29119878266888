import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    public isModalOpen = false;
    constructor(private ngxModalSvc: NgxSmartModalService) { }

    open(type, title, message) {
        this.isModalOpen = true;
        const messageData = {
            type: type,
            title: title,
            message: message
        };
        setTimeout(() => {
            console.log(this.ngxModalSvc.getModalStack());
            this.ngxModalSvc.setModalData(messageData, 'messageModal', true);
            this.ngxModalSvc.getModal('messageModal').open();
        }, 0);
    }
}
